<mat-card (click)="action()">
    <mat-card-content>
        <img *ngIf="image; else defaultImage" [src]="image.props.medium | cdnFileUploadedResolver"  alt="Tour image"/>
        <ng-template #defaultImage>
            <img src="./assets/images/default-image.jpg" alt="Tour image"/>
        </ng-template>
    </mat-card-content>
    <mat-card-header>
        <mat-card-title *ngIf="title">
            {{title.props.content}}
        </mat-card-title>
        <mat-card-subtitle *ngIf="description">
            {{description.props.content}}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-footer>
        <div *ngIf="tour.minutes_duration">
            <mat-icon aria-hidden="false">schedule</mat-icon>
            {{'i18n.tourDurationMinutes' | translate : {number: tour.minutes_duration.toString()} }}
        </div>
        <div>
            <mat-icon svgIcon="sign_language" aria-hidden="false" aria-label="sign-language" *ngIf="tour.enable_sign_language"></mat-icon>
            <mat-icon svgIcon="subtitle" aria-hidden="false" aria-label="sign-language" *ngIf="tour.enable_subtitles"></mat-icon>
        </div>
    </mat-card-footer>
</mat-card>
