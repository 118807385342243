<div class="information-wrapper">
    <mat-expansion-panel *ngFor="let expansionPanel of expansionPanels" #matExpansionPanel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{expansionPanel.title.props.content}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="expansionPanel.content.props.mimetype === 'text/html'; else plainText">
            <div class="html-content" [innerHTML]="expansionPanel.content.props.content | sanitizeHtml"></div>
        </div>
        <ng-template #plainText>
            <div>
                {{expansionPanel.content.props.content}}
            </div>
        </ng-template>
    </mat-expansion-panel>
</div>

