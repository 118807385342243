<app-accessibility-wrapper [accessibility]="templateContent.accessible_information">
  <div class="video-wrapper" block1>
    <video #videoPlayer crossorigin muted  [attr.data-tracking-uuid]="video.uuid" *ngIf="video!==null">
      <source [src]="video.props.default | cdnFileUploadedResolver" type="video/mp4"/>
    </video>
    <div class="hidden" *ngIf="audioTrack!==null">
      <audio crossorigin  [attr.data-tracking-uuid]="audioTrack.uuid">
        <source [src]="audioTrack.props.file | cdnFileUploadedResolver" type="audio/mpeg"/>
      </audio>
    </div>
  </div>
  <div block2>
    <app-basic-information [properties]="templateContent.basic_information"></app-basic-information>
  </div>
</app-accessibility-wrapper>
