import {Component, Input, OnInit} from '@angular/core';
import {LanguageService} from "@services/language.service";
import {Content, RichTextMediaResource} from "@models/ventour";
import {VentourService} from "@services/ventour.service";

@Component({
  selector: 'app-navigation-tree-content-title',
  templateUrl: './navigation-tree-content-title.component.html',
  styleUrls: ['./navigation-tree-content-title.component.scss']
})
export class NavigationTreeContentTitleComponent implements OnInit {

  @Input() public uuid!: string;
  public title: string = '';

  public constructor(
      private ventourService: VentourService,
      private languageService: LanguageService
  ) { }

  public ngOnInit(): void {
    this.setTitleByLanguage();
    this.languageService.languageChanged.subscribe(() => {
      this.setTitleByLanguage();
    });
  }

  public setTitleByLanguage(): void {

    this.ventourService.getContent(this.uuid).subscribe({
      next: (content: Content|null) => {
        if (content === null) {
          return;
        }
        if (content[content.template_key].basic_information) {
          const found: RichTextMediaResource|null = this.ventourService.findProperty<RichTextMediaResource>(
            content[content.template_key].basic_information.title,
            this.languageService.getLanguage()
          );
          if (found !== null) {
            this.title = found.props.content;
          }
        }
      }
    });
  }
}
