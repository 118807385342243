import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ImageMediaResource, RichTextMediaResource} from "@models/ventour";

export type DialogPassedData = {
  title: RichTextMediaResource|null;
  confirmationMessage: RichTextMediaResource|null;
  image: ImageMediaResource|null;
  enableSignLanguage: boolean;
  enableSubtitles: boolean;
  onConfirmation: (answeredSignLanguage: boolean, answeredSubtitles: boolean) => void;
}

@Component({
  selector: 'app-tour-confirmation',
  templateUrl: './tour-confirmation.component.html',
  styleUrls: ['./tour-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TourConfirmationComponent implements OnInit {

  public answeredAccessibilitySignsLanguage: boolean = false;
  public answeredAccessibilitySubtitles: boolean = false;

  public constructor(
      @Inject(MAT_DIALOG_DATA) public data: DialogPassedData,
      public dialogRef: MatDialogRef<TourConfirmationComponent>
  ) {
  }

  public ngOnInit(): void {
  }

  public confirm(): void {
    this.dialogRef.close();
    this.data.onConfirmation(this.answeredAccessibilitySignsLanguage, this.answeredAccessibilitySubtitles);
  }
}
