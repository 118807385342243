import {Component, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss']
})
export class VisitComponent {

  public sidenavToggleEventEmitter: EventEmitter<void>;

  public constructor() {
    this.sidenavToggleEventEmitter = new EventEmitter<void>();
  }
}
