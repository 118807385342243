import {Component, Input, OnInit} from '@angular/core';
import {ImageMediaResource, TemplateContentImage} from "@models/ventour";
import {from, Observable} from "rxjs";
import {LanguageService} from "@services/language.service";
import {TrackingService} from "@services/tracking.service";
import {FullscreenImageComponent} from "@components/fullscreen-image/fullscreen-image.component";
import {VentourService} from "@services/ventour.service";

@Component({
  selector: 'app-template-content-image',
  templateUrl: './template-content-image.component.html',
  styleUrls: ['./template-content-image.component.scss']
})
export class TemplateContentImageComponent implements OnInit {

  @Input() public templateContent!: TemplateContentImage;
  @Input() public enableFullscreen: boolean = true;

  public image: ImageMediaResource|null = null;
  public lazyFullscreenImageComponent: Observable<typeof FullscreenImageComponent> = from(
      import('@components/fullscreen-image/fullscreen-image.component').then(
          (component) => component.FullscreenImageComponent
      )
  );
  public languageCodeSelected!: string;

  public constructor(
      private languageService: LanguageService,
      private trackingService: TrackingService,
      private ventourService: VentourService
  ) {
    this.languageCodeSelected = this.languageService.getLanguage();
  }

  public ngOnInit(): void {
    this.preset();
    this.languageService.languageChanged.subscribe((languageIsoCode: string) => {
      this.languageCodeSelected = languageIsoCode;
      this.preset();
    });
  }

  private preset(): void {
    this.image = this.ventourService.findProperty<ImageMediaResource>(
      this.templateContent.single_image.default_image,
      this.languageCodeSelected
    );
  }

  public onExitModeFullScreen(timeElapsed: number): void {
    this.trackingService.fullscreenMode(this.templateContent.single_image.uuid, timeElapsed);
  }
}
