import {Component, Input, OnInit} from '@angular/core';
import {environment} from "@env/environment";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {ViewportScroller} from "@angular/common";


@Component({
  selector: 'app-tour-tabs-selector',
  templateUrl: './tour-tabs-selector.component.html',
  styleUrls: ['./tour-tabs-selector.component.scss']
})
export class TourTabsSelectorComponent implements OnInit {

  @Input() public uuid!: string;

  public optionSelected!: string;
  public disableMapOption: boolean;

  public constructor(
      public router: Router,
      public activatedRoute: ActivatedRoute,
      private viewportScroller: ViewportScroller,
  ) {
    this.disableMapOption = environment.production;
  }

  public ngOnInit(): void {

    this.setOptionByUrl();
    this.router.events.subscribe((event: unknown) => {
      if (event instanceof NavigationEnd) {
        this.setOptionByUrl();
      }
    });
  }

  public navigate(uri: string): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.router.navigate(['/visit/tour/' + this.uuid + '/' + uri]).then();
  }

  private setOptionByUrl(): void {
    this.optionSelected = 'view';
  }
}
