<div>
    <div class="display-result" *ngIf="passedData.result; else wrongResult">
        <div>
            <mat-icon svgIcon="right-face" aria-hidden="false" aria-label="right-face"></mat-icon>
        </div>
        <div>
            <div *ngIf="passedData.rightAnsweredText">
                {{passedData.rightAnsweredText.props.content}}
            </div>
        </div>
        <div>
            <button mat-raised-button color="primary" (click)="dialogRef.close()">
                {{'i18n.quizResultClose' | translate}}
            </button>
        </div>
    </div>
    <ng-template #wrongResult>
        <div class="display-result">
            <div>
                <mat-icon svgIcon="wrong-face" aria-hidden="false" aria-label="wrong-face"></mat-icon>
            </div>
            <div>
                <div *ngIf="passedData.wrongAnsweredText">
                    {{passedData.wrongAnsweredText.props.content}}
                </div>
            </div>
            <div>
                <button mat-raised-button color="primary" (click)="dialogRef.close()">
                    {{'i18n.quizResultRetry' | translate}}
                </button>
            </div>
        </div>
    </ng-template>
</div>

