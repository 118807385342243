<div *ngIf="currentTreeNode; else treeNodeNotFoundDisplay">
    <div *ngIf="currentTreeNode.children">
        <div [ngSwitch]="currentTreeNode.display_mode">
            <app-navigation-tree-list *ngSwitchCase="'list'" [treeNodes]="currentTreeNode.children" (navigateTo)="onNavigate($event)"></app-navigation-tree-list>
            <app-navigation-tree-cards *ngSwitchCase="'cards'" [treeNodes]="currentTreeNode.children" (navigateTo)="onNavigate($event)"></app-navigation-tree-cards>
        </div>
    </div>
</div>
<ng-template #treeNodeNotFoundDisplay>
    <!-- TODO -->
</ng-template>


