export class TimeElapsedRegisterService {

  private timeElapsedValue: number = 0;
  private timeElapsedInterval: any;

  public start(): void {
    if (this.timeElapsedInterval) {
      this.destroy();
    }
    this.timeElapsedValue = 0;
    this.timeElapsedInterval = setInterval(() => {
      this.timeElapsedValue++;
    }, 1000);
  }

  public getValue(): number {
    return this.timeElapsedValue;
  }

  public destroy(): void {
    clearInterval(this.timeElapsedInterval);
  }
}
