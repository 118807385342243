import {TranslateLoader} from "@ngx-translate/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

export class CustomTranslateLoaderService implements TranslateLoader {

  public constructor(
    private httpClient: HttpClient,
    private host: string,
    private clientId: string
  ) {
  }

  public getTranslation(lang: string): Observable<any> {

    const url: string = this.host + '/i18n/' + this.clientId + '/' + lang + '.json';
    const headers: HttpHeaders = new HttpHeaders().set('accept', 'application/json');

    return this.httpClient.get(
      url,
      {headers}
    );
  }
}
