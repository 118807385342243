import {DomSanitizer} from "@angular/platform-browser";
import {MatIconRegistry} from "@angular/material/icon";
import {Inject, Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class IconLoaderService {

    private readonly icons: string[]= [
        'sign_language',
        'subtitle'
    ];

    public constructor(
      @Inject('APP_CDN_HOST') public host: string,
      private iconRegistry: MatIconRegistry,
      private domSanitizer: DomSanitizer
    ) {}

    private iconUrlResolver(iconName: string): string {
        return `${this.host}/ventour-client-full-preview/images/icons/${iconName}-min.svg`;
    }

    public load(): void {
        for (const icon of this.icons) {
            this.iconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(this.iconUrlResolver(icon)));
        }
    }
}
