import {Directive, ViewContainerRef} from "@angular/core";

@Directive({
    selector: '[contentTemplateOutlet]'
})
export class ContentTemplateOutletDirective {
    public constructor(
        public viewContainerRef: ViewContainerRef
    ) {
    }
}
