import {Component, EventEmitter, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Location, ViewportScroller} from '@angular/common';
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationBarComponent implements OnInit {

  @Input() public sidenavToggleEventEmitter!: EventEmitter<void>;

  public title: string|null = null;

  public constructor(
    private location: Location,
    private viewportScroller: ViewportScroller,
    private titleService: TitleService
  ) { }

  public ngOnInit(): void {
    this.titleService.onChange((title: string) => {
      this.title = title;
    });
  }

  public openSidenav(): void {
    this.sidenavToggleEventEmitter.emit();
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  public back(): void {
    this.location.back();
  }
}
