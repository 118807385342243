import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidenavComponent} from "./sidenav/sidenav.component";
import {NavigationBarComponent} from "./navigation-bar/navigation-bar.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {TranslateModule} from "@ngx-translate/core";
import {MatListModule} from "@angular/material/list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {ErrorDisplayComponent} from "./error-display/error-display.component";
import {MatDialogModule} from "@angular/material/dialog";
import {TourTabsSelectorComponent} from "./tour-tabs-selector/tour-tabs-selector.component";
import {SpinnerWrapperComponent} from './spinner-wrapper/spinner-wrapper.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FullscreenCloseButtonComponent} from "./fullscreen-close-button/fullscreen-close-button.component";
import {ContentViewComponent} from "./content-view/content-view.component";
import {BasicInformationComponent} from "./basic-information/basic-information.component";
import {AudioPlayerComponent} from "./audio-player/audio-player.component";
import {FullscreenActionComponent} from "./fullscreen-action/fullscreen-action.component";
import {FullscreenWrapperComponent} from "./fullscreen-wrapper/fullscreen-wrapper.component";
import {
  NavigationTreeContentTitleComponent
} from "./navigation-tree-content-title/navigation-tree-content-title.component";
import {FullscreenImageComponent} from "./fullscreen-image/fullscreen-image.component";
import {
  TemplateContentCarouselImagesComponent
} from "./template-content-carousel-images/template-content-carousel-images.component";
import {TourCardComponent} from "./tour-card/tour-card.component";
import {NavigationTreeComponent} from "./navigation-tree/navigation-tree.component";
import {TemplateContentQuizComponent} from "./template-content-quiz/template-content-quiz.component";
import {TourConfirmationComponent} from "./tour-confirmation/tour-confirmation.component";
import {NavigationTreeCardsComponent} from "./navigation-tree-cards/navigation-tree-cards.component";
import {FullscreenCarouselImagesComponent} from "./fullscreen-carousel-images/fullscreen-carousel-images.component";
import {ContentCardComponent} from "./content-card/content-card.component";
import {NavigationTreeListComponent} from "./navigation-tree-list/navigation-tree-list.component";
import {TemplateContentVideoComponent} from "./template-content-video/template-content-video.component";
import {TemplateContentImageComponent} from "./template-content-image/template-content-image.component";
import {QuizDisplayResultsComponent} from "./quiz-display-results/quiz-display-results.component";
import {
  TemplateContentInformationScreenComponent
} from "./template-content-information-screen/template-content-information-screen.component";
import {ImagesSliderComponent} from "./images-slider/images-slider.component";
import {ContentTemplateOutletDirective} from "./content-view/content-template-outlet.directive";
import {PipesModule} from "@pipes/pipes.module";
import {MapDisplayComponent} from "@components/map-display/map-display.component";
import {SwiperModule} from "swiper/angular";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {FormsModule} from "@angular/forms";
import {MatCardModule} from "@angular/material/card";
import {CdnFileUploadedResolverPipe} from "@pipes/cdn-file-uploaded-resolver.pipe";
import { AccessibilityWrapperComponent } from './accessibility-wrapper/accessibility-wrapper.component';
import {DragDropModule} from "@angular/cdk/drag-drop";


@NgModule({
  declarations: [
    SidenavComponent,
    NavigationBarComponent,
    ErrorDisplayComponent,
    TourTabsSelectorComponent,
    SpinnerWrapperComponent,
    NavigationTreeComponent,
    NavigationTreeContentTitleComponent,
    AudioPlayerComponent,
    TourCardComponent,
    FullscreenWrapperComponent,
    FullscreenActionComponent,
    FullscreenCloseButtonComponent,
    FullscreenImageComponent,
    FullscreenCarouselImagesComponent,
    NavigationTreeListComponent,
    ContentViewComponent,
    TourConfirmationComponent,
    NavigationTreeCardsComponent,
    ContentCardComponent,
    TemplateContentVideoComponent,
    TemplateContentImageComponent,
    TemplateContentInformationScreenComponent,
    TemplateContentCarouselImagesComponent,
    BasicInformationComponent,
    ImagesSliderComponent,
    MapDisplayComponent,
    TemplateContentQuizComponent,
    QuizDisplayResultsComponent,
    ContentTemplateOutletDirective,
    AccessibilityWrapperComponent
  ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatToolbarModule,
        TranslateModule,
        MatListModule,
        MatSidenavModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        SwiperModule,
        PipesModule,
        MatSlideToggleModule,
        FormsModule,
        MatCardModule,
        DragDropModule
    ],
    exports: [
        SidenavComponent,
        NavigationBarComponent,
        TourTabsSelectorComponent,
        SpinnerWrapperComponent,
        NavigationTreeComponent,
        TourCardComponent,
        ContentViewComponent,
        MapDisplayComponent,
        TemplateContentInformationScreenComponent,
        ContentCardComponent
    ],
  providers: [
    CdnFileUploadedResolverPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {
}
