import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from "rxjs";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FullscreenWrapperComponent} from "@components/fullscreen-wrapper/fullscreen-wrapper.component";

@Component({
  selector: 'app-fullscreen-action',
  templateUrl: './fullscreen-action.component.html',
  styleUrls: ['./fullscreen-action.component.scss']
})
export class FullscreenActionComponent {

  @Output() public onExitMode: EventEmitter<number> = new EventEmitter();
  @Input() public dynamicComponent!: Observable<any>;
  @Input() public dynamicComponentInjectProvideData: any;

  private dialogRef!: MatDialogRef<FullscreenWrapperComponent>;

  public constructor(
      private matDialogService: MatDialog
  ) { }

  public showDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    const dynamicComponent = {
      component: this.dynamicComponent,
      injectProvideData: this.dynamicComponentInjectProvideData
    };
    this.dialogRef = this.matDialogService.open(FullscreenWrapperComponent, {
      data: dynamicComponent,
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh'
    });
    this.dialogRef.componentInstance.onExitMode.subscribe((timeElapsed: number) => this.onExitMode.emit(timeElapsed));
  }
}
