import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ImageMediaResource, RichTextMediaResource} from "@models/ventour";
import SwiperCore, {Navigation, Pagination} from "swiper";
import {PaginationOptions} from "swiper/types";
import Swiper from "swiper";

export type ImageSlider = {
  caption?: RichTextMediaResource|null;
  image: ImageMediaResource|null;
  position: number;
}

@Component({
  selector: 'app-images-slider',
  templateUrl: './images-slider.component.html',
  styleUrls: ['./images-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImagesSliderComponent implements OnInit {

  @Output() public onImageSlide: EventEmitter<[number, number]> = new EventEmitter();
  @Input() public slides!: ImageSlider[];
  private currentPosition: number = 0;

  public paginationOptions: PaginationOptions = {
    dynamicBullets: true,
    clickable: true
  };

  public constructor() {
    SwiperCore.use([Pagination, Navigation]);
  }

  public ngOnInit(): void {
    if (this.slides.length) {
      this.currentPosition = this.slides[0].position;
    }
  }

  public imageTrackBy(index: number, image: ImageSlider): string {
    return image.image?.uuid as string;
  }

  public slideChange(event: [swiper: Swiper]): void {
    this.onImageSlide.emit([this.currentPosition, this.slides[event[0].realIndex].position]);
    this.currentPosition = this.slides[event[0].realIndex].position;
  }
}
