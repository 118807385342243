<app-accessibility-wrapper [accessibility]="templateContent.accessible_information">
  <div block1 *ngIf="slides.length">
    <!-- Fullscreen -->
    <app-fullscreen-action *ngIf="enableFullscreen; else carouselImagesTemplate"
                           [dynamicComponent]="lazyFullscreenCarouselImagesComponent"
                           [dynamicComponentInjectProvideData]="slides"
                           (onExitMode)="onExitModeFullScreen($event)">
      <div content>
        <ng-container *ngTemplateOutlet="carouselImagesTemplate"></ng-container>
      </div>
    </app-fullscreen-action>
    <!-- Carousel images -->
    <ng-template #carouselImagesTemplate>
      <app-images-slider [slides]="slides" (onImageSlide)="onCarouselImageSlide($event)"></app-images-slider>
    </ng-template>
  </div>
  <div block2>
    <app-basic-information [properties]="templateContent.basic_information"></app-basic-information>
  </div>
</app-accessibility-wrapper>
