import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {TreeNode, TreeNodeLanguageExclusion} from "@models/ventour";
import {LanguageService} from "@services/language.service";

@Component({
  selector: 'app-navigation-tree-list',
  templateUrl: './navigation-tree-list.component.html',
  styleUrls: ['./navigation-tree-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationTreeListComponent implements OnInit {

  @Output() public navigateTo: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  @Input() public treeNodes!: TreeNode[];

  public treeNodesFilteredByLanguage!: TreeNode[];

  public constructor(
      public languageService: LanguageService
  ) { }

  public ngOnInit(): void {
    this.languageService.languageChanged.subscribe(() => {
      this.preset();
    });
    this.preset();
  }

  private preset(): void {
    this.treeNodesFilteredByLanguage = this.treeNodes.filter((treeNode: TreeNode) => {
      const isoCodes: string[] = treeNode.language_exclusion.map((treeNodeLanguageExclusion: TreeNodeLanguageExclusion) => {
        return treeNodeLanguageExclusion.language_iso_code;
      });
      return !isoCodes.includes(this.languageService.getLanguage());
    });
  }
}
