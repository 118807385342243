import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RichTextMediaResource} from "@models/ventour";

export type MatDialogPassedData = {
  wrongAnsweredText: RichTextMediaResource|null;
  rightAnsweredText: RichTextMediaResource|null;
  result: boolean;
}

@Component({
  selector: 'app-quiz-display-results',
  templateUrl: './quiz-display-results.component.html',
  styleUrls: ['./quiz-display-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuizDisplayResultsComponent {
  public constructor(
      @Inject(MAT_DIALOG_DATA) public passedData: MatDialogPassedData,
      public dialogRef: MatDialogRef<QuizDisplayResultsComponent>,
  ) { }
}
