import { Component, OnInit } from '@angular/core';
import {VentourService} from "@services/ventour.service";
import {CustomerPlaceLanguage} from "@models/ventour";
import {ErrorCodes, ErrorService} from "@services/error.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {TrackingService} from "@services/tracking.service";

@Component({
  selector: 'app-languages-selection',
  templateUrl: './languages-selection.component.html',
  styleUrls: ['./languages-selection.component.scss']
})
export class LanguagesSelectionComponent implements OnInit {

  public loader: boolean = false;
  public customerPlaceLanguages: CustomerPlaceLanguage[] = [];
  public currentLanguage!: string;

  public constructor(
    private ventourService: VentourService,
    private errorService: ErrorService,
    private router: Router,
    private translateService: TranslateService,
    private trackingService: TrackingService
  ) { }

  public ngOnInit(): void {
    this.loader = true;
    this.currentLanguage = this.translateService.currentLang || this.translateService.getDefaultLang();
    this.ventourService.getLanguages().subscribe({
      next: (customerPlaceLanguages: CustomerPlaceLanguage[]) => {
        this.customerPlaceLanguages = customerPlaceLanguages;
        this.loader = false;
      },
      error: _ => this.errorService.display(ErrorCodes.VENTOUR_HTTP_CONNECTION_ERROR)
    });
  }

  public select(customerPlaceLanguage: CustomerPlaceLanguage): void {
    this.translateService.use(customerPlaceLanguage.iso_code).subscribe({
      next: () => {
        this.translateService.use(customerPlaceLanguage.iso_code);
        this.router.navigate(['home']).then(
          () => {
            this.trackingService.languageSelected(customerPlaceLanguage.iso_code);
          }
        );
      }
    });
  }

  public languageTrackBy(index: number, language: CustomerPlaceLanguage): string {
    return language.iso_code;
  }
}
