import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LanguageService} from "@services/language.service";
import {RichTextMediaResource, TemplateContentQuiz} from "@models/ventour";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TrackingService} from "@services/tracking.service";
import {
  MatDialogPassedData,
  QuizDisplayResultsComponent
} from "@components/quiz-display-results/quiz-display-results.component";
import {VentourService} from "@services/ventour.service";
import {TimeElapsedRegisterService} from "@services/time-elapsed-register.service";

type QuizAnswer = {
  text: RichTextMediaResource;
  right: boolean;
  position: number;
}

@Component({
  selector: 'app-template-content-quiz',
  templateUrl: './template-content-quiz.component.html',
  styleUrls: ['./template-content-quiz.component.scss'],
  providers: [
    TimeElapsedRegisterService
  ]
})
export class TemplateContentQuizComponent implements OnInit, OnDestroy {

  @Input() public templateContent!: TemplateContentQuiz;

  public questionText: RichTextMediaResource|null = null;
  public wrongAnsweredText: RichTextMediaResource|null = null;
  public rightAnsweredText: RichTextMediaResource|null = null;
  public helpQuestionText: RichTextMediaResource|null = null;
  public answers: QuizAnswer[] = [];
  private matDialogRef!: MatDialogRef<QuizDisplayResultsComponent>;
  private languageCodeSelected!: string;

  public constructor(
      private languageService: LanguageService,
      private matDialogService: MatDialog,
      private trackingService: TrackingService,
      private ventourService: VentourService,
      private timeElapsedRegisterService: TimeElapsedRegisterService
  ) {
    this.languageCodeSelected = this.languageService.getLanguage();
  }

  public ngOnInit(): void {
    this.preset();
    this.languageService.languageChanged.subscribe((languageIsoCode: string) => {
      this.languageCodeSelected = languageIsoCode;
      this.preset();
    });
    this.timeElapsedRegisterService.start();
  }

  private preset(): void {

    this.questionText = this.ventourService.findProperty<RichTextMediaResource>(
      this.templateContent.question.question_text,
      this.languageCodeSelected
    );
    this.wrongAnsweredText = this.ventourService.findProperty<RichTextMediaResource>(
      this.templateContent.question.wrong_answered_text,
      this.languageCodeSelected
    );
    this.rightAnsweredText = this.ventourService.findProperty<RichTextMediaResource>(
      this.templateContent.question.right_answered_text,
      this.languageCodeSelected
    );
    this.helpQuestionText = this.ventourService.findProperty<RichTextMediaResource>(
      this.templateContent.question.help_question_text,
      this.languageCodeSelected
    );
    this.answers = [];
    for (const optionSelectorProperty of this.templateContent.option_selector) {
      const text: RichTextMediaResource|null = this.ventourService.findProperty<RichTextMediaResource>(
        optionSelectorProperty.option,
        this.languageCodeSelected
      );
      const right: boolean = optionSelectorProperty.option.attributes?.['right'];
      if (text !== null) {
        this.answers.push({
          text,
          right,
          position: optionSelectorProperty.position as number
        });
      }
    }
  }

  public setAnswer(answer: QuizAnswer): void {

    this.trackingService.optionSelected(this.templateContent.option_selector[0].uuid, answer.position, this.timeElapsedRegisterService.getValue());

    const passedData: MatDialogPassedData = {
      result: answer.right,
      wrongAnsweredText: this.wrongAnsweredText,
      rightAnsweredText: this.rightAnsweredText
    };
    this.matDialogRef = this.matDialogService.open(QuizDisplayResultsComponent, {
      data: passedData,
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh'
    });
  }

  public ngOnDestroy(): void {
    this.timeElapsedRegisterService.destroy();
  }
}
