import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";
import {Router} from "@angular/router";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() public toggleEventEmitter!: EventEmitter<void>;
  @ViewChild('sidenav') public matSidenav!: MatSidenav;

  public constructor(
      private changeDetectorRef: ChangeDetectorRef,
      private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.toggleEventEmitter.subscribe({
      next: () => {
        this.matSidenav.toggle().then();
      }
    });
  }

  public navigate(path: string): void {
    this.router.navigate([path]).then();
    this.matSidenav.toggle(false).then();
  }
}
