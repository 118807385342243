export const environment = {
  production: true,
  ventour: {
    defaultHost: 'https://staging.api.ventour.gvam.com',
    clientId: '99d8e173-a995-4363-b912-06546d728271',
    clientSecret: 'zgfmfyE3s142xNlc1WHRFtemBHIFnBd7JgxTtGpv',
    defaultCustomerPlaceCode: '5MTB',
    environment: 'qa'
  },
  sendTrackingData: true,
  ventourCdnUrl: 'https://s3.eu-west-2.amazonaws.com/ventour-cdn.gvam-staging',
  appCdn: {
    host: 'https://apps-cdn.gvam.es',
    defaultNamespace: 'ventour-client-full-preview'
  },
  i18n: {
    defaultLanguage: 'es-ES'
  },
  previewCommunication: {
    host: 'https://staging.preview.communication-service.gvam.com',
    jwt: 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW50b3VyLWNsaWVudC1mdWxsLXByZXZpZXciLCJpc3MiOiJndmFtIiwiZXhwIjoiMjAyNy0wNi0wMVQwNzoyMzoyNi45MzlaIn0.mkR0_1UW_fytpFW57I7jYDlzyhtK_pv_WsvYMQX_AXk'
  }
};
