import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-fullscreen-close-button',
  templateUrl: './fullscreen-close-button.component.html',
  styleUrls: ['./fullscreen-close-button.component.scss']
})
export class FullscreenCloseButtonComponent {
  @Output() public onClosed: EventEmitter<void> = new EventEmitter();
}
