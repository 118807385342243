import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {DropdownListProperties, RichTextMediaResource, TemplateContentInformationScreen} from "@models/ventour";
import {MatExpansionPanel} from "@angular/material/expansion";
import {LanguageService} from "@services/language.service";
import {VentourService} from "@services/ventour.service";

type ExpansionPanel = {
  title: RichTextMediaResource;
  content: RichTextMediaResource;
};

@Component({
  selector: 'app-template-content-information-screen',
  templateUrl: './template-content-information-screen.component.html',
  styleUrls: ['./template-content-information-screen.component.scss']
})
export class TemplateContentInformationScreenComponent implements OnInit {

  @Input() public templateContent!: TemplateContentInformationScreen;
  @ViewChildren('matExpansionPanel') public matExpansionPanels!: QueryList<MatExpansionPanel>;

  public expansionPanels: ExpansionPanel[] = [];

  public constructor(
      private languageService: LanguageService,
      private ventourService: VentourService
  ) {
  }

  public ngOnInit(): void {
    this.preset();
    this.languageService.languageChanged.subscribe(() => {
      this.preset();
    });
  }

  private preset(): void {

    this.expansionPanels = [];
    this.templateContent.dropdown_list.forEach((dropdownListProperties: DropdownListProperties) => {
      let title = this.ventourService.findProperty<RichTextMediaResource>(
        dropdownListProperties.title,
        this.languageService.getLanguage()
      );
      let content = this.ventourService.findProperty<RichTextMediaResource>(
        dropdownListProperties.content,
        this.languageService.getLanguage()
      );
      if (title !== null && content !== null) {
        this.expansionPanels.push({
          title: title,
          content: content,
        });
      }
    });
  }
}
