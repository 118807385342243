import {Inject, Pipe, PipeTransform} from '@angular/core';
import {FileUploaded} from "@models/ventour";

@Pipe({
  name: 'cdnFileUploadedResolver'
})
export class CdnFileUploadedResolverPipe implements PipeTransform {

  private directoryMap: Map<string, string> = new Map();

  public constructor(
      @Inject('MEDIA_SOURCE_CDN_URL') public host: string
  ) {
    this.directoryMap.set('image/jpeg', 'image');
    this.directoryMap.set('audio/mpeg', 'audio');
    this.directoryMap.set('video/mp4', 'video');
  }

  public transform(fileUploaded: FileUploaded|null): string {

    if (!fileUploaded) {
      return '';
    }
    let directory = 'file';
    if (this.directoryMap.has(fileUploaded?.mimetype)) {
      directory = this.directoryMap.get(fileUploaded.mimetype) as string;
    }
    let url = this.host;
    url += '/';
    url += directory;
    url += '/';
    url += fileUploaded.filename;

    return url;
  }
}
