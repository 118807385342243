<mat-dialog-content>
    <div class="error-display-wrapper">
        <p>{{'i18n.errorMessage' | translate}}</p>
        <span>{{'i18n.errorCode' | translate}}: {{data.code}}</span>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="redirectToHome()">
        {{'i18n.close' | translate}}
    </button>
</mat-dialog-actions>
