import {NgModule} from '@angular/core';
import {AppCdnResolverPipe} from "./app-cdn-resolver.pipe";
import {CdnFileUploadedResolverPipe} from "@pipes/cdn-file-uploaded-resolver.pipe";
import {RawPlayerTimePipe} from "@pipes/raw-player-time.pipe";
import {SanitizeHtmlPipe} from "@pipes/sanitize-html.pipe";


@NgModule({
  declarations: [
    AppCdnResolverPipe,
    CdnFileUploadedResolverPipe,
    RawPlayerTimePipe,
    SanitizeHtmlPipe
  ],
  exports: [
    AppCdnResolverPipe,
    CdnFileUploadedResolverPipe,
    RawPlayerTimePipe,
    SanitizeHtmlPipe
  ],
  imports: [
  ]
})
export class PipesModule { }
