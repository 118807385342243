import {Component, EventEmitter, Inject, Injector, OnDestroy, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {TimeElapsedRegisterService} from "@services/time-elapsed-register.service";

type MatDialogDynamicComponent<T> = {
  component: Observable<T>;
  injectProvideData: T
};

@Component({
  selector: 'app-fullscreen-wrapper',
  templateUrl: './fullscreen-wrapper.component.html',
  styleUrls: ['./fullscreen-wrapper.component.scss'],
  providers: [
    TimeElapsedRegisterService
  ]
})
export class FullscreenWrapperComponent implements OnInit, OnDestroy {

  @Output() public onExitMode: EventEmitter<number> = new EventEmitter<number>();

  public injector!: Injector;

  public constructor(
      @Inject(MAT_DIALOG_DATA) public dynamicComponent: MatDialogDynamicComponent<any>,
      private timeElapsedRegisterService: TimeElapsedRegisterService,
      injector: Injector
  ) {
    this.injector = Injector.create({providers: [{provide: 'InjectProvideData', useValue: this.dynamicComponent.injectProvideData}], parent: injector});
  }

  public ngOnInit(): void {
    this.timeElapsedRegisterService.start();
  }

  public ngOnDestroy(): void {
    this.timeElapsedRegisterService.destroy();
  }

  public onClosed(): void {
    this.onExitMode.emit(this.timeElapsedRegisterService.getValue());
  }
}
