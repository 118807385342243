import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rawPlayerTime'
})
export class RawPlayerTimePipe implements PipeTransform {

  public transform(seconds: number): string {
    if (seconds === Infinity || seconds === 0 || isNaN(seconds)){
      return '00:00';
    }
    const minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds % 60);
    let minutesLeadingPad = minutes < 10 ? `0${minutes}` : `${minutes}`;
    let secondsLeadingPad = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutesLeadingPad}:${secondsLeadingPad}`;
  }
}
