import {Injectable} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TitleService {

    private events: Subject<string> = new Subject<string>();

    public constructor(
        private title: Title
    ) {
    }

    public setTitle(value: string): void {
        this.title.setTitle(value);
        this.events.next(value);
    }

    public onChange(handler: (title: string) => void): void {
        this.events.subscribe(handler);
    }
}
