import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Content} from "@models/ventour";
import {ContentTemplateOutletDirective} from "@components/content-view/content-template-outlet.directive";
import {TemplateContentQuizComponent} from "@components/template-content-quiz/template-content-quiz.component";
import {TemplateContentVideoComponent} from "@components/template-content-video/template-content-video.component";
import {TemplateContentImageComponent} from "@components/template-content-image/template-content-image.component";
import {
  TemplateContentCarouselImagesComponent
} from "@components/template-content-carousel-images/template-content-carousel-images.component";

@Component({
  selector: 'app-content-view',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.scss']
})
export class ContentViewComponent implements OnInit {

  @ViewChild(ContentTemplateOutletDirective, {static: true}) contentTemplateOutlet!: ContentTemplateOutletDirective;
  @Input() public content!: Content;

  private mapTemplate: Map<string, any> = new Map();

  public ngOnInit(): void {

    this.mapTemplate.set('carousel_images_template', TemplateContentCarouselImagesComponent);
    this.mapTemplate.set('video_template', TemplateContentVideoComponent);
    this.mapTemplate.set('single_image_template', TemplateContentImageComponent);
    this.mapTemplate.set('quiz_template', TemplateContentQuizComponent);

    const viewContainerRef = this.contentTemplateOutlet.viewContainerRef;
    viewContainerRef.clear();

    if (!this.mapTemplate.has(this.content.template_key)) {
      console.warn('Content template not supported', this.content.template_key);
      return;
    }
    const componentRef = viewContainerRef.createComponent<any>(this.mapTemplate.get(this.content.template_key));
    componentRef.instance.templateContent = this.content[this.content.template_key];
  }
}
