<div>
    <button mat-button [ngClass]="{'selected': optionSelected === 'view'}">
        <mat-icon>format_list_bulleted</mat-icon>
        {{'i18n.list' | translate}}
    </button>
    <button mat-button [ngClass]="{'selected': optionSelected === 'map'}">
        <mat-icon>map</mat-icon>
        {{'i18n.map' | translate}}
    </button>
</div>
