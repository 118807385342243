<div class="images-slider-wrapper">
    <swiper [centeredSlides]="true" [pagination]="paginationOptions" [navigation]="true" (slideChange)="slideChange($event)">
        <ng-template ngFor let-slide [ngForOf]="slides" let-i="index" [ngForTrackBy]="imageTrackBy">
            <ng-template swiperSlide>
                <div class="image-wrapper" *ngIf="slide.image !== null">
                    <img [src]="slide.image.props.default | cdnFileUploadedResolver" alt="Image slide"/>
                    <div class="image-label" *ngIf="slide.caption">
                        {{ slide.caption.props.content }}
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </swiper>
</div>
