<mat-sidenav-container>
        <mat-sidenav position="end" #sidenav mode="over">
          <mat-drawer-container>
            <div class="sidenav-logo">
              <img [src]="'gvam.webp' | appCdnResolver : 'common'" alt="Sidenav logo">
            </div>
            <mat-nav-list>
              <a mat-list-item (click)="navigate('/home')">
                <mat-icon>home</mat-icon>
                {{ 'i18n.home' | translate | uppercase }}
              </a>
              <a mat-list-item (click)="navigate('/visit/tours')">
                <mat-icon>route</mat-icon>
                {{ 'i18n.tours' | translate | uppercase }}
              </a>
              <a mat-list-item (click)="navigate('/visit/keyboard')">
                <mat-icon>dialpad</mat-icon>
                {{ 'i18n.keyboard' | translate | uppercase }}
              </a>
              <a mat-list-item (click)="navigate('/languages-selection')">
                <mat-icon>g_translate</mat-icon>
                {{ 'i18n.languages' | translate | uppercase }}
              </a>
              <a mat-list-item (click)="navigate('/visit/map')">
                <mat-icon>map</mat-icon>
                {{ 'i18n.map' | translate | uppercase }}
              </a>
              <a mat-list-item (click)="navigate('/visit/information')">
                <mat-icon>info</mat-icon>
                {{ 'i18n.information' | translate | uppercase }}
              </a>
            </mat-nav-list>
          </mat-drawer-container>

        </mat-sidenav>
        <mat-sidenav-content>
            <ng-content></ng-content>
        </mat-sidenav-content>
    </mat-sidenav-container>
