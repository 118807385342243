import {InjectionToken, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HomeComponent} from '@home/home.component';
import {LanguagesSelectionComponent} from '@languagesSelection//languages-selection.component';
import packageJson from '../../package.json';
import {MatButtonModule} from "@angular/material/button";
import {environment} from "@env/environment";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {PipesModule} from "@pipes/pipes.module";
import {MatDialogModule} from "@angular/material/dialog";
import {ComponentsModule} from "@components/components.module";
import {ServiceWorkerModule} from "@angular/service-worker";
import {CustomTranslateLoaderService} from "@services/custom-translate-loader.service";

export const TRANSLATIONS_CDN_HOST = new InjectionToken<string>('TRANSLATIONS_CDN_HOST');
export const VENTOUR_CLIENT_ID = new InjectionToken<string>('VENTOUR_CLIENT_ID');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LanguagesSelectionComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoaderService,
        deps: [HttpClient, TRANSLATIONS_CDN_HOST, VENTOUR_CLIENT_ID]
      }
    }),
    MatButtonModule,
    PipesModule,
    MatDialogModule,
    ComponentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [
    {provide: 'APP_VERSION', useValue: packageJson.version},
    {provide: 'VENTOUR_DEFAULT_HOST', useValue: environment.ventour.defaultHost},
    {provide: VENTOUR_CLIENT_ID, useValue: environment.ventour.clientId},
    {provide: 'VENTOUR_CLIENT_SECRET', useValue: environment.ventour.clientSecret},
    {provide: 'DEFAULT_CUSTOMER_PLACE_CODE', useValue: environment.ventour.defaultCustomerPlaceCode},
    {provide: 'APP_CDN_HOST', useValue: environment.appCdn.host},
    {provide: 'APP_CDN_DEFAULT_NAMESPACE', useValue: environment.appCdn.defaultNamespace},
    {provide: 'VENTOUR_ENVIRONMENT', useValue: environment.ventour.environment},
    {provide: 'SEND_TRACKING_DATA', useValue: environment.sendTrackingData},
    {provide: 'MEDIA_SOURCE_CDN_URL', useValue: environment.ventourCdnUrl},
    {provide: 'PREVIEW_COMMUNICATION_HOST', useValue: environment.previewCommunication.host},
    {provide: 'PREVIEW_COMMUNICATION_TOKEN', useValue: environment.previewCommunication.jwt},
    {provide: TRANSLATIONS_CDN_HOST, useValue: environment.ventourCdnUrl}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
