<div [ngClass]="{'hidden': hide}">
    <div id="audio-player-container" #audioPlayerContainer>
        <button mat-icon-button aria-label="toggle" (click)="togglePlay()" *ngIf="mainMediaElement">
            <mat-icon *ngIf="mainMediaElement.nativeElement.paused; else pauseIcon">play_arrow</mat-icon>
            <ng-template #pauseIcon>
                <mat-icon>pause</mat-icon>
            </ng-template>
        </button>
        <span>
            {{currentTime | rawPlayerTime}}
        </span>
        <input #seek type="range" [max]="currentDuration" [value]="currentTime" />
        <span class="time">
            {{currentDuration | rawPlayerTime}}
        </span>
        <button mat-icon-button aria-label="repeat" data-title="repeat" class="volume-button" (click)="repeat()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
</div>



