<div class="navigation-tree-list-wrapper">
    <mat-action-list>
        <ng-template ngFor let-treeNode [ngForOf]="treeNodesFilteredByLanguage">
            <!-- TODO: Language exclusion -->
            <div [attr.id]="treeNode.uuid">
                <!-- TODO: Noninteractive -->
                <button mat-list-item (click)="navigateTo.emit(treeNode)" *ngIf="true; else nonInteractiveNode">
                    <ng-container *ngTemplateOutlet="contentTitle"></ng-container>
                    <mat-icon>chevron_right</mat-icon>
                </button>
                <ng-template #nonInteractiveNode>
                    <div class="non-interactive-node">
                        <ng-container *ngTemplateOutlet="contentTitle"></ng-container>
                    </div>
                </ng-template>
                <ng-template #contentTitle>
                    <app-navigation-tree-content-title *ngIf="treeNode.content_uuid" [uuid]="treeNode.content_uuid"></app-navigation-tree-content-title>
                </ng-template>
            </div>
        </ng-template>
    </mat-action-list>
</div>

