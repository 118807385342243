import {Component, Input, OnInit} from '@angular/core';
import {Content, ImageMediaResource, RichTextMediaResource} from "@models/ventour";
import {LanguageService} from "@services/language.service";
import {VentourService} from "@services/ventour.service";

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent implements OnInit {

  @Input() public uuid!: string;

  public coverImage: ImageMediaResource|null = null;
  public title: RichTextMediaResource|null = null;
  public keyboardCode: string|null = null;

  public constructor(
      private ventourService: VentourService,
      private languageService: LanguageService
  ) { }

  public ngOnInit(): void {
    this.languageService.languageChanged.subscribe(() => {
      this.preset();
    });
    this.preset();
  }

  public preset(): void {
    this.coverImage = null;
    this.title = null;
    this.ventourService.getContent(this.uuid).subscribe({
      next: (content: Content|null) => {
        if (content === null) {
          return;
        }
        if (content.locators.length) {
          this.keyboardCode = content.locators[0];
        }
        if (!content[content.template_key]) {
          return;
        }
        if (content[content.template_key].basic_information) {
          this.title = this.ventourService.findProperty<RichTextMediaResource>(
            content[content.template_key].basic_information.title,
            this.languageService.getLanguage()
          );
        }
        if (content[content.template_key].basic_information) {
          this.coverImage = this.ventourService.findProperty<ImageMediaResource>(
            content[content.template_key].basic_information.cover_image,
            this.languageService.getLanguage()
          );
        }
      }
    });
  }
}
