import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Map, MapContent, MapLayer, MapLayerLanguage, RichTextMediaResource} from "@models/ventour";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import SwiperCore, {SwiperOptions, Zoom} from "swiper";
import {TrackingService} from "@services/tracking.service";
import {CdnFileUploadedResolverPipe} from "@pipes/cdn-file-uploaded-resolver.pipe";
import {LanguageService} from "@services/language.service";

@Component({
  selector: 'app-map-display',
  templateUrl: './map-display.component.html',
  styleUrls: ['./map-display.component.scss']
})
export class MapDisplayComponent implements OnInit {

  @Output() public contentSelected: EventEmitter<string|null> = new EventEmitter();
  @ViewChild('svgRendered') public svgRendered!: ElementRef;
  @Input() public map!: Map;

  public loader: boolean = true;
  public svg!: string;
  public languageSelected!: string;
  private mapLayerTextSize: number = 18;
  public swiperOptions: SwiperOptions = {
    zoom: {
      maxRatio: 5,
      minRatio: 1
    }
  };

  public constructor(
      private httpClient: HttpClient,
      private cdnFileUploadedResolver: CdnFileUploadedResolverPipe,
      private trackingService: TrackingService,
      private languageService: LanguageService
  ) {
    SwiperCore.use([Zoom]);
    this.languageSelected = this.languageService.getLanguage();
  }

  public ngOnInit(): void {
    this.preset();
  }

  private preset(): void {
    this.loader = true;
    if (this.map.media_resource === null || this.map.media_resource.type !== 'file') {
      return;
    }
    this.httpClient.get(
      this.cdnFileUploadedResolver.transform(this.map.media_resource.props.file),
      {
        headers: new HttpHeaders().set('Accept', 'image/svg+xml'),
        responseType: 'text'
      }
    ).subscribe({
      next: (response: string) => {

        /**
         * Create SVG element
         */
        const svg: SVGElement|null = document.createRange().createContextualFragment(response).querySelector('svg');

        if (svg === null || svg?.getAttribute('viewBox') === null) {
          console.warn('SVG map is not readable');
          return;
        }
        const viewBoxValues: string[] = (svg.getAttribute('viewBox') as string).split(' ');
        const groupScale = document.createElementNS('http://www.w3.org/2000/svg','g');
        groupScale.setAttribute('transform', `scale(1, 1)`);

        /**
         * Map contents, create Nodes, assign events
         */
        this.map.contents.forEach((mapContent: MapContent) => {

          const axisX = ((parseInt(viewBoxValues[2]) * mapContent.x) / 100) + 2;
          const axisY = ((parseInt(viewBoxValues[3]) * mapContent.y) / 100) + 38;

          const groupMarker = document.createElementNS('http://www.w3.org/2000/svg','g');
          groupMarker.setAttribute('transform', `translate(${axisX}, ${axisY})`);

          const path = document.createElementNS('http://www.w3.org/2000/svg','path');
          path.setAttribute('d', 'M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z');
          path.setAttribute('fill', '#3d933d');

          const circle = document.createElementNS('http://www.w3.org/2000/svg','circle');
          circle.setAttribute('fill', '#ffffff');
          circle.setAttribute('cx', '14');
          circle.setAttribute('cy', '14');
          circle.setAttribute('r', '7');
          circle.dataset['uuid'] = mapContent.content_uuid as string;

          groupMarker.appendChild(path);
          groupMarker.appendChild(circle);
          groupScale.appendChild(groupMarker);
        });
        svg.appendChild(groupScale);

        /**
         * Map layers, create nodes
         */
        this.map.layers.forEach((mapLayer: MapLayer) => {

          const language: MapLayerLanguage|undefined = mapLayer.languages.find((mapLayerLanguage: MapLayerLanguage) => {
            return this.languageSelected === mapLayerLanguage.language_iso_code;
          });
          if (language === undefined) {
            return;
          }
          const axisX = (parseInt(viewBoxValues[2]) * mapLayer.x) / 100;
          const axisY = (parseInt(viewBoxValues[3]) * mapLayer.y) / 100;
          const groupLayer = document.createElementNS('http://www.w3.org/2000/svg','g');
          groupLayer.setAttribute('transform', `translate(${axisX-5}, ${axisY+5})`);

          const text = document.createElementNS('http://www.w3.org/2000/svg','text');
          text.setAttribute('font-size', this.mapLayerTextSize.toString());
          text.innerHTML = (language.media_resource as RichTextMediaResource).props.content;
          text.classList.add('map-layer');
          groupLayer.appendChild(text);
          svg.appendChild(groupLayer);
        });

        this.svg = svg.outerHTML;
        this.loader = false;
        setTimeout(() => {
          this.svgRendered.nativeElement.querySelectorAll('[data-uuid]').forEach((node: HTMLElement) => {
            node.addEventListener('click', (event: any) => {
              this.contentClickHandler(event.target.dataset.uuid);
            });
          });
        });
      }
    });
  }

  public onZoomChange(event: [swiper: SwiperCore, scale: number, image: HTMLElement, slideEl: HTMLElement]): void {
    if (!this.map.media_resource || event[1] == 1) {
      return;
    }
    this.trackingService.zoomImage(this.map.media_resource?.uuid);
  }

  public contentClickHandler(uuid: string): void {
    this.contentSelected.emit(uuid);
  }
}
