import {
  KeyboardSearchInteraction, MapViewedInteraction,
  ResizeTextInteraction,
  SlideInteraction, StartVisitInteraction,
  UserTrackingServiceContract, ZoomImageInteraction
} from "@models/tracking";
import {Inject, Injectable} from "@angular/core";
import {LanguageService} from "@services/language.service";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {VentourService} from "@services/ventour.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorCodes, ErrorService} from "@services/error.service";
import {
  ContentViewedInteraction,
  FloatingSignLanguageInteraction,
  FullscreenModeInteraction, Interaction,
  LanguageSelectedInteraction, OptionSelectedInteraction, PlayerMediaInteraction, PurchaseCodeInteraction,
  TourAccessInteraction
} from "@models/tracking";


@Injectable({
  providedIn: 'root'
})
export class TrackingService implements UserTrackingServiceContract {

  private fingerprint: string = '';

  public constructor(
    @Inject('APP_VERSION') public version: string,
    @Inject('DEFAULT_CUSTOMER_PLACE_CODE') public customerPlaceCode: string,
    @Inject('SEND_TRACKING_DATA') public send: boolean,
    private languageService: LanguageService,
    private ventourService: VentourService,
    private errorService: ErrorService
  ) {
    dayjs.extend(utc);
  }

  public setFingerprint(value: string): void {
    this.fingerprint = value;
  }

  public keyboardSearch(value: string, found: boolean): void {
    const interaction: KeyboardSearchInteraction = {
      ...this.commonProperties(),
      type: 'KeyboardSearch',
      value,
      found
    };
    this.handle(interaction);
  }

  public resizeText(uuid: string, from: number, to: number) {
    const interaction: ResizeTextInteraction = {
      ...this.commonProperties(),
      type: 'ResizeText',
      widget_uuid: uuid,
      from,
      to
    };
    this.handle(interaction);
  }

  public slide(uuid: string, from: number, to: number) {
    const interaction: SlideInteraction = {
      ...this.commonProperties(),
      type: 'Slide',
      widget_uuid: uuid,
      from,
      to,
      time_elapsed: 0
    };
    this.handle(interaction);
  }

  public startVisit(timeElapsed: number): void {
    const interaction: StartVisitInteraction = {
      ...this.commonProperties(),
      type: 'StartVisit',
      time_elapsed: timeElapsed
    };
    this.handle(interaction);
  }

  public languageSelected(isoCode: string): void {
    const interaction: LanguageSelectedInteraction = {
      ...this.commonProperties(),
      type: 'LanguageSelected',
      changed_to: isoCode
    };
    this.handle(interaction);
  }

  public tourAccess(uuid: string, enableSignLanguageSelected: boolean, enableSubtitlesSelected: boolean, timeElapsed: number): void {
    const interaction: TourAccessInteraction = {
      ...this.commonProperties(),
      type: 'TourAccess',
      tour_uuid: uuid,
      enable_sign_language_selected: enableSignLanguageSelected,
      enable_subtitles_selected: enableSubtitlesSelected,
      enable_audio_description_selected: false,
      time_elapsed: timeElapsed
    };
    this.handle(interaction);
  }

  public contentViewed(uuid: string, timeElapsed: number): void {
    const interaction: ContentViewedInteraction = {
      ...this.commonProperties(),
      type: 'ContentViewed',
      content_uuid: uuid,
      time_elapsed: timeElapsed
    };
    this.handle(interaction);
  }

  public playerMedia(uuid: string, action: 'play' | 'pause'| 'stop', timeElapsed: number): void {
    const interaction: PlayerMediaInteraction = {
      ...this.commonProperties(),
      type: 'PlayerMedia',
      action,
      media_resource_uuid: uuid,
      time_elapsed: timeElapsed
    };
    this.handle(interaction);
  }

  public fullscreenMode(uuid: string, timeElapsed: number): void {
    const interaction: FullscreenModeInteraction = {
      ...this.commonProperties(),
      type: 'FullscreenMode',
      widget_uuid: uuid,
      time_elapsed: timeElapsed
    };
    this.handle(interaction);
  }

  public mapViewed(uuid: string, timeElapsed: number): void {
    const interaction: MapViewedInteraction = {
      ...this.commonProperties(),
      type: 'MapViewed',
      map_uuid: uuid,
      time_elapsed: timeElapsed
    };
    this.handle(interaction);
  }

  public zoomImage(uuid: string): void {
    const interaction: ZoomImageInteraction = {
      ...this.commonProperties(),
      type: 'ZoomImage',
      media_resource_uuid: uuid
    };
    this.handle(interaction);
  }

  public floatingSignLanguage(uuid: string, x: number, y: number, action: 'close' | 'resize' | 'move', resized: boolean): void {
    const interaction: FloatingSignLanguageInteraction = {
      ...this.commonProperties(),
      type: 'FloatingSignLanguage',
      x,
      y,
      action: action,
      resized,
      media_resource_uuid: uuid
    };
    this.handle(interaction);
  }

  private commonProperties(): Interaction {
    return {
      version: this.version,
      fingerprint: this.fingerprint,
      customer_place_code: this.customerPlaceCode,
      date: this.getFormattedDate(),
      language_iso_code: this.languageService.getLanguage(),
      publication_released_uuid: this.ventourService.getPublicationReleasedUuid(),
      platform: 'web'
    };
  }

  private handle(interaction: Interaction): void {
    if (!this.send) {
      return;
    }
    this.ventourService.sendTrackingInteractions([interaction]).subscribe({
      error: (error: HttpErrorResponse) => {
        this.errorService.display(ErrorCodes.TRACKING_INTERACTIONS_SENT_FAILED);
      }
    });
  }

  private getFormattedDate(): string {
    return dayjs.utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
  }

  public purchaseCode(value: string, validated: boolean): void {
    const interaction: PurchaseCodeInteraction = {
      type: 'PurchaseCode',
      validated,
      value,
      ...this.commonProperties()
    };
    this.handle(interaction);
  }

  public optionSelected(uuid: string, option: number, timeElapsed: number): void {
    const interaction: OptionSelectedInteraction = {
      type: 'OptionSelected',
      widget_uuid: uuid,
      option,
      time_elapsed: timeElapsed,
      ...this.commonProperties()
    };
    this.handle(interaction);
  }
}
