import {Inject, Injectable} from "@angular/core";
import {Observable, of, switchMap, tap} from "rxjs";
import {
  Content,
  CustomerPlace,
  CustomerPlaceLanguage,
  PublicationReleaseEnvironment,
  Map,
  Tour, TemplateContentPropertyValue
} from "@models/ventour";
import {HttpClient, HttpParams} from "@angular/common/http";
import {VENTOUR_CLIENT_ID} from "../app.module";
import {Interaction} from "@models/tracking";

export interface FullReleaseResponse {
  publication_released_uuid: string;
  customer_place: CustomerPlace;
  languages: string[];
}

export interface AccessTokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
}

@Injectable({
  providedIn: 'root'
})
export class VentourService {

  private accessToken: string | null = null;
  private tokenExpirationTime: number = 0;
  private publicationReleasedUuid: string|null = null;
  private fullReleaseResponseCached: FullReleaseResponse|null = null;

  public constructor(
    @Inject('VENTOUR_DEFAULT_HOST') private host: string,
    @Inject(VENTOUR_CLIENT_ID) private clientId: string,
    @Inject('VENTOUR_CLIENT_SECRET') private clientSecret: string,
    @Inject('DEFAULT_CUSTOMER_PLACE_CODE') private defaultCustomerPlaceCode: string,
    @Inject('VENTOUR_ENVIRONMENT') private environment: PublicationReleaseEnvironment,
    private httpClient: HttpClient
  ) {
  }

  public getPublicationRelease(): Observable<FullReleaseResponse> {

    if (this.fullReleaseResponseCached) {
      return of(this.fullReleaseResponseCached);
    }
    return this.getAccessToken()
      .pipe(
        switchMap(
          (token: string) => {
            const params: {[K: string]: any} = {
              'customer_place_code': this.defaultCustomerPlaceCode,
              'environment': this.environment
            };
            if (this.publicationReleasedUuid !== null) {
              params['publication_released_uuid'] =  this.publicationReleasedUuid;
            }
            return this.httpClient.get<FullReleaseResponse>(
              this.host + '/api/v1/publication-released',
              {
                headers: {
                  'Authorization': 'Bearer ' + token
                },
                params
              }
            ).pipe(
              tap(
                (response: FullReleaseResponse) => {
                  this.publicationReleasedUuid = response.publication_released_uuid;
                  this.fullReleaseResponseCached = response;
                }
              )
            );
          }
        )
      );
  }

  public getLanguages(): Observable<CustomerPlaceLanguage[]> {
    return this.getPublicationRelease().pipe(
      switchMap(
        (response: FullReleaseResponse) => {

          /**
           * Filter languages
           */
          const filtered: CustomerPlaceLanguage[] = response.customer_place.languages.filter((language: CustomerPlaceLanguage) => {
            return response.languages.includes(language.iso_code);
          });
          return of(filtered);
        }
      )
    );
  }

  public getContents(): Observable<Content[]> {
    return this.getPublicationRelease().pipe(
      switchMap(
        (response: FullReleaseResponse) => {
          return of(response.customer_place.contents as Content[]);
        }
      )
    );
  }

  public getContent(uuid: string): Observable<Content|null> {
    return this.getContents().pipe(
      switchMap(
        (contents: Content[]) => {
          const found = contents.find((content: Content) => content.uuid === uuid);

          return found !== undefined ? of(found) : of(null);
        }
      )
    );
  }

  public getTours(): Observable<Tour[]> {
    return this.getPublicationRelease().pipe(
      switchMap(
        (response: FullReleaseResponse) => {
          return of(response.customer_place.tours as Tour[]);
        }
      )
    );
  }

  public getTour(uuid: string): Observable<Tour|null> {
    return this.getTours().pipe(
      switchMap(
        (tours: Tour[]) => {
          const found = tours.find((tour: Tour) => tour.uuid === uuid);

          return found !== undefined ? of(found as Tour) : of(null);
        }
      )
    );
  }

  public getMaps(): Observable<Map[]> {
    return this.getPublicationRelease().pipe(
      switchMap(
        (response: FullReleaseResponse) => {
          return of(response.customer_place.maps as Map[]);
        }
      )
    );
  }

  public getMap(uuid: string): Observable<Map|null> {
    return this.getMaps().pipe(
      switchMap(
        (maps: Map[]) => {
          const found = maps.find((map: Map) => map.uuid === uuid);

          return found !== undefined ? of(found as Map) : of(null);
        }
      )
    );
  }

  private getAccessToken(): Observable<string> {

    if (this.accessToken !== null && this.tokenExpirationTime > new Date().getTime()) {
      return of(this.accessToken);
    }
    const payload = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('client_id', this.clientId)
      .set('client_secret', this.clientSecret);
    return this.httpClient.post<AccessTokenResponse>(
      this.host + '/oauth/token',
      payload,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    ).pipe(
      switchMap(
        (accessTokenResponse: AccessTokenResponse) => {
          this.accessToken = accessTokenResponse.access_token;
          this.tokenExpirationTime = new Date().getTime() + (accessTokenResponse.expires_in * 1000);

          return of(this.accessToken);
        }
      )
    );
  }

  public findContentByKeyboardValue(value: string): Observable<Content|null> {

    return this.getContents().pipe(
      switchMap(
        (contents: Content[]) => {
          const found = contents.find((content: Content) => {
            return content.locators.includes(value);
          });
          return found !== undefined ? of(found) : of(null);
        }
      )
    );
  }

  public setEnvironment(environment: PublicationReleaseEnvironment): void {
    this.environment = environment;
  }

  public setPublicationReleasedUuid(uuid: string): void {
    this.publicationReleasedUuid = uuid;
  }

  public getPublicationReleasedUuid(): string|null {
    return this.publicationReleasedUuid;
  }

  public sendTrackingInteractions(interactions: Interaction[]): Observable<void> {
    return this.getAccessToken()
      .pipe(
        switchMap(
          (token: string) => {
            return this.httpClient.post<void>(
              this.host + '/api/v1/tracking/interactions',
              {interactions},
              {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
              }
            );
          }
        )
      );
  }

  public findProperty<MediaResourceType>(property: TemplateContentPropertyValue<MediaResourceType>, currentLanguage: string): MediaResourceType|null {
    if (property.languages.hasOwnProperty(currentLanguage) && property.languages[currentLanguage]) {
      return property.languages[currentLanguage];
    }
    return null;
  }
}
