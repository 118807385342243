<app-spinner-wrapper [loader]="loader">
  <div content class="languages-selection-wrapper">
    <div>
      <img [src]="'gvam.webp' | appCdnResolver : 'common' " alt="Logo">
    </div>
    <div>
      <ng-template ngFor let-customerPlaceLanguage [ngForOf]="customerPlaceLanguages" [ngForTrackBy]="languageTrackBy">
        <div>
          <button mat-button (click)="select(customerPlaceLanguage)" [ngClass]="{'language-selected': customerPlaceLanguage.iso_code === currentLanguage}">
            {{ 'i18n.' + customerPlaceLanguage.iso_code | translate }}
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</app-spinner-wrapper>
