import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NavigationTree, TreeNode} from "@models/ventour";
import {LanguageService} from "@services/language.service";


@Component({
  selector: 'app-navigation-tree',
  templateUrl: './navigation-tree.component.html',
  styleUrls: ['./navigation-tree.component.scss']
})
export class NavigationTreeComponent implements OnInit, OnChanges {

  @Output() public navigateTo: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  @Input() public navigationTree!: NavigationTree;
  @Input() public navigationTrackUuid: string|null = null;

  public currentTreeNode: TreeNode|null = null;

  public constructor(
      private languageService: LanguageService
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['navigationTrackUuid']) {
      this.preset();
    }
  }

  public ngOnInit(): void {
    this.preset();
    this.languageService.languageChanged.subscribe(() => {
      this.preset();
    });
  }

  private preset(): void {
    this.currentTreeNode = null;
    if (this.navigationTrackUuid !== null) {
      const treeNode: TreeNode|null = this.findTreeNode(this.navigationTrackUuid);
      if (treeNode) {
        this.currentTreeNode = treeNode;
      }
    } else {
      this.currentTreeNode = this.navigationTree.tree_node;
    }
  }

  private findTreeNode(uuid: string): TreeNode|null {

    let found: TreeNode|null = null;

    const finder = (treeNode: TreeNode) => {
      if (treeNode.uuid === uuid) {
        found = treeNode;
        return;
      }
      if (!treeNode.children) {
        return;
      }
      for (const child of treeNode.children) {
        finder(child);
      }
    };
    finder(this.navigationTree.tree_node);

    return found;
  }

  public onNavigate(treeNode: TreeNode): void {
    this.navigateTo.emit(treeNode);
  }
}
