import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {AudioMulticastService} from "@services/audio-multicast.service";
import {Subscription} from "rxjs";
import {TrackingService} from "@services/tracking.service";
import {TimeElapsedRegisterService} from "@services/time-elapsed-register.service";

@Component({
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.scss'],
    providers: [
      TimeElapsedRegisterService
    ]
})
export class AudioPlayerComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('audioPlayerContainer') audioPlayerContainer!: ElementRef<HTMLDivElement>;
    @ViewChild('seek') public seek!: ElementRef;

    public currentDuration: number = 0;
    public currentTime: number = 0;
    public hide!: boolean;
    private subscription!: Subscription;
    public mainMediaElement!: ElementRef<HTMLAudioElement | HTMLVideoElement>;
    private slavesMediaElements: ElementRef<HTMLAudioElement | HTMLVideoElement>[] = [];

    public constructor(
        private audioMulticastService: AudioMulticastService,
        private trackingService: TrackingService,
        private timeElapsedService: TimeElapsedRegisterService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public ngOnInit(): void {
        this.hide = true;
        this.subscription = this.audioMulticastService.audioMediaSourceListener.subscribe((elements: ElementRef[]) => {
            if (elements.length === 0) {
                return;
            }
            this.preset();
            this.mainMediaElement = elements[0];
            this.slavesMediaElements = [...elements.slice(1, elements.length)];
            this.checkEqualsDurationSlaves();
            this.handleEvents(this.mainMediaElement);
            this.timeElapsedService.start();
            this.hide = false;
            this.changeDetectorRef.detectChanges();
        });
    }

    private checkEqualsDurationSlaves(): boolean {
        for (const element of this.slavesMediaElements) {
            if (this.mainMediaElement.nativeElement.duration !== element.nativeElement.duration) {
                console.warn('Main media duration not equals with slave media');
                return false;
            }
        }
        return true;
    }

    public ngAfterViewInit(): void {
        this.handleTimeLineEvents();
    }

    private handleTimeLineEvents(): void {
        this.seek.nativeElement.removeEventListener('change', null);
        this.seek.nativeElement.addEventListener('change', () => {
            if (this.mainMediaElement === undefined) {
                return;
            }
            this.mainMediaElement.nativeElement.currentTime = this.seek.nativeElement.value;
            this.propagateHandlerSlaves((element: ElementRef) => element.nativeElement.currentTime = this.seek.nativeElement.value);
            this.updateTimeLine();
        });
    }

    public togglePlay(): void {
        if (this.mainMediaElement.nativeElement.paused) {
            this.mainMediaElement.nativeElement.play();
            this.propagateHandlerSlaves((element: ElementRef) => {
                element.nativeElement.play();
            });
        } else {
            this.mainMediaElement.nativeElement.pause();
            this.propagateHandlerSlaves((element: ElementRef) => element.nativeElement.pause());
        }
    }

    public repeat(): void {
        this.mainMediaElement.nativeElement.pause();
        this.mainMediaElement.nativeElement.currentTime = 0;

        this.propagateHandlerSlaves((element: ElementRef) => {
            this.mainMediaElement.nativeElement.pause();
            element.nativeElement.currentTime = 0;
        });
    }

    private propagateHandlerSlaves(callback: (element: ElementRef) => void): void {
        for (let i: number = 0; i < this.slavesMediaElements.length; i++) {
            callback(this.slavesMediaElements[i]);
        }
    }

    private handleEvents(element: ElementRef): void {

        element.nativeElement.removeEventListener('timeupdate', null);
        element.nativeElement.removeEventListener('loadedmetadata', null);
        element.nativeElement.removeEventListener('play', null);
        element.nativeElement.removeEventListener('pause', null);

        element.nativeElement.addEventListener('timeupdate', () => {
            this.currentTime = Math.floor(element.nativeElement.currentTime);
            this.updateTimeLine();
        });
        element.nativeElement.addEventListener('loadedmetadata', () => this.currentDuration = Math.floor(element.nativeElement.duration));
        element.nativeElement.addEventListener('play', () => this.trackInteraction('play'));
        element.nativeElement.addEventListener('pause', () => this.trackInteraction('pause'));
    }

    private trackInteraction(type: 'play' | 'pause' | 'stop'): void {
        if (!this.mainMediaElement) {
          return;
        }
        const timeElapsed: number = this.timeElapsedService.getValue();;

        let uuid: string | null = this.mainMediaElement.nativeElement.getAttribute('data-tracking-uuid');
        if (uuid === null) {
            console.warn('Cannot retrieve media resource uuid from main media element', this.mainMediaElement.nativeElement);
            return;
        }
        this.trackingService.playerMedia(uuid, type, timeElapsed);
        this.slavesMediaElements.forEach((element: ElementRef<HTMLAudioElement | HTMLVideoElement>) => {
            let uuid: string | null = element.nativeElement.getAttribute('data-tracking-uuid');
            if (uuid === null) {
                console.warn('Cannot retrieve media resource uuid from slave media element', element.nativeElement);
                return;
            }
            this.trackingService.playerMedia(element.nativeElement.getAttribute('data-tracking-uuid') as string, type, timeElapsed);
        });
        this.timeElapsedService.start();
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.trackInteraction('stop');
        this.timeElapsedService.destroy();
    }

    private updateTimeLine(): void {
        this.audioPlayerContainer.nativeElement.style.setProperty(
            '--seek-before-width',
            Number(this.seek.nativeElement.value) / Number(this.seek.nativeElement.max) * 100 + '%'
        );
        const bufferLength: number = this.mainMediaElement.nativeElement.buffered.length - 1 >= 0 ?
            this.mainMediaElement.nativeElement.buffered.end(this.mainMediaElement.nativeElement.buffered.length - 1) : 0;
        if (!isNaN(bufferLength)) {
            this.audioPlayerContainer.nativeElement.style.setProperty('--buffered-width', `${(bufferLength / this.seek.nativeElement.max) * 100}%`);
        }
        this.seek.nativeElement.value = this.currentTime;
    }

    private preset(): void {
        if (this.mainMediaElement === undefined) {
            return;
        }
        this.slavesMediaElements = [];
        this.currentDuration = 0;
        this.currentTime = 0;
        this.mainMediaElement.nativeElement.pause();
        this.handleTimeLineEvents();
        this.updateTimeLine();
    }
}
