<div>
  <ng-content select="[block1]"></ng-content>
  <app-audio-player></app-audio-player>
  <div class="hidden">
    <audio #locutionAudioPlayerElement crossorigin [attr.data-tracking-uuid]="locution?.uuid"></audio>
  </div>
  <ng-content select="[block2]"></ng-content>
  <div *ngIf="signLanguage!==null" cdkDrag cdkDragBoundary="body" [cdkDragFreeDragPosition]="{x: 250, y: 225}" class="sign-language-floating" (cdkDragEnded)="onDragEnded($event)" [ngClass]="{'hide' : hideSignLanguageFloating, 'resize': resizedSignLanguageFloating}">
    <button mat-icon-button (click)="resizeSignLanguageFloating()" class="resize" [ngClass]="{'active': resizedSignLanguageFloating}">
      <mat-icon>open_in_new</mat-icon>
    </button>
    <button mat-icon-button (click)="closeSignLanguageFloating()" class="close">
      <mat-icon>close</mat-icon>
    </button>
    <video #signLanguagePlayer crossorigin muted [attr.data-tracking-uuid]="signLanguage.uuid">
      <source [src]="signLanguage.props.default | cdnFileUploadedResolver" type="video/mp4"/>
    </video>
  </div>
</div>
