import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ImageMediaResource, RichTextMediaResource, Tour} from "@models/ventour";
import {LanguageService} from "@services/language.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogPassedData, TourConfirmationComponent} from "@components/tour-confirmation/tour-confirmation.component";
import {TrackingService} from "@services/tracking.service";
import {VentourService} from "@services/ventour.service";
import {TimeElapsedRegisterService} from "@services/time-elapsed-register.service";

@Component({
  selector: 'app-tour-card',
  templateUrl: './tour-card.component.html',
  styleUrls: ['./tour-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    TimeElapsedRegisterService
  ]
})
export class TourCardComponent implements OnInit, OnDestroy {

  @Output() public onActionSelected: EventEmitter<Tour> = new EventEmitter();
  @Input() public tour!: Tour;

  public image: ImageMediaResource|null = null;
  public title: RichTextMediaResource|null = null;
  public description: RichTextMediaResource|null = null;
  public confirmationMessage: RichTextMediaResource|null = null;

  public constructor(
      private languageService: LanguageService,
      private matDialogService: MatDialog,
      private trackingService: TrackingService,
      private ventourService: VentourService,
      private timeElapsedRegisterService: TimeElapsedRegisterService
  ) { }

  public ngOnInit(): void {
    this.timeElapsedRegisterService.start();
    this.preset();
    this.languageService.languageChanged.subscribe(() => {
      this.preset();
    });
  }

  public action(): void {
    if (!this.tour.enable_subtitles && !this.tour.enable_sign_language) {
      this.trackingService.tourAccess(this.tour.uuid as string, false, false, this.timeElapsedRegisterService.getValue());
      this.onActionSelected.emit(this.tour);
    } else {
      this.matDialogService.open(TourConfirmationComponent, {
        data: {
          title: this.title,
          confirmationMessage: this.confirmationMessage,
          image: this.image,
          enableSubtitles: this.tour.enable_subtitles,
          enableSignLanguage: this.tour.enable_sign_language,
          onConfirmation: (answeredSignLanguage: boolean, answeredSubtitles: boolean) => {
            this.trackingService.tourAccess(this.tour.uuid as string, answeredSignLanguage, answeredSubtitles, this.timeElapsedRegisterService.getValue());
            this.onActionSelected.emit(this.tour);
          }
        } as DialogPassedData,
        panelClass: 'tour-confirmation-dialog-container'
      });
    }
  }

  public preset(): void {

    this.image = this.ventourService.findProperty<ImageMediaResource>(
      this.tour.card.default_image,
      this.languageService.getLanguage()
    );
    this.title = this.ventourService.findProperty<RichTextMediaResource>(
      this.tour.card.title,
      this.languageService.getLanguage()
    );
    this.description = this.ventourService.findProperty<RichTextMediaResource>(
      this.tour.card.description,
      this.languageService.getLanguage()
    );
    this.confirmationMessage = this.ventourService.findProperty<RichTextMediaResource>(
      this.tour.card.confirmation_message,
      this.languageService.getLanguage()
    );
  }

  public ngOnDestroy(): void {
    this.timeElapsedRegisterService.destroy();
  }
}
