import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {TrackingService} from "@services/tracking.service";
import {TimeElapsedRegisterService} from "@services/time-elapsed-register.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    TimeElapsedRegisterService
  ]
})
export class HomeComponent implements OnInit, OnDestroy {

  public constructor(
    @Inject('APP_VERSION') public appVersion: string,
    private router: Router,
    private trackingService: TrackingService,
    private timeElapsedRegisterService: TimeElapsedRegisterService
  ) { }

  public ngOnInit(): void {
    this.timeElapsedRegisterService.start();
  }

  public startVisit(): void {
    this.router.navigate(['visit/tours/list']).then(() => {
      this.trackingService.startVisit(this.timeElapsedRegisterService.getValue());
    });
  }

  public ngOnDestroy(): void {
    this.timeElapsedRegisterService.destroy();
  }
}
