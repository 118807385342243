import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BasicInformationProperties, RichTextMediaResource} from "@models/ventour";
import {LanguageService} from "@services/language.service";
import {TrackingService} from "@services/tracking.service";
import {CdnFileUploadedResolverPipe} from "@pipes/cdn-file-uploaded-resolver.pipe";
import {VentourService} from "@services/ventour.service";

@Component({
  selector: 'app-basic-information',
  templateUrl: './basic-information.component.html',
  styleUrls: ['./basic-information.component.scss']
})
export class BasicInformationComponent implements OnInit {

  @Input() public properties!: BasicInformationProperties;

  public title: RichTextMediaResource|null = null;
  public paragraph: RichTextMediaResource|null = null;
  public readonly maxZoomValue: number = 3;
  public zoom: number = 0;
  private languageCodeSelected!: string;

  public constructor(
      private cdnFileUploadedResolver: CdnFileUploadedResolverPipe,
      private languageService: LanguageService,
      private trackingService: TrackingService,
      private ventourService: VentourService
  ) {
    this.languageCodeSelected = this.languageService.getLanguage();
  }

  public ngOnInit(): void {
    this.preset();
    this.languageService.languageChanged.subscribe((languageIsoCode: string) => {
      this.languageCodeSelected = languageIsoCode;
      this.preset();
    });
  }

  public preset(): void {

    this.title = this.ventourService.findProperty<RichTextMediaResource>(
      this.properties.title,
      this.languageCodeSelected
    );
    this.paragraph = this.ventourService.findProperty<RichTextMediaResource>(
      this.properties.paragraph,
      this.languageCodeSelected
    );
  }

  public increaseZoom(): void {

    if (this.zoom < this.maxZoomValue) {
      this.trackingService.resizeText(this.properties.uuid, this.zoom, ++this.zoom);
    }
  }

  public decreaseZoom(): void {
    if (this.zoom > 0) {
      this.trackingService.resizeText(this.properties.uuid, this.zoom, --this.zoom);
    }
  }
}
