import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-spinner-wrapper',
  templateUrl: './spinner-wrapper.component.html',
  styleUrls: ['./spinner-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerWrapperComponent  {
  @Input() public loader!: boolean;
  public readonly diameter: number = 65;
}
