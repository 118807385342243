import {Injectable} from "@angular/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ErrorDisplayComponent} from "@components/error-display/error-display.component";
import {Subject} from "rxjs";

export enum ErrorCodes {
  VENTOUR_HTTP_CONNECTION_ERROR = 'ERR_1',
  VENTOUR_CONTENT_NOT_FOUND = 'ERR_2',
  KEYBOARD_NOT_FOUND = 'ERR_3',
  TRACKING_INTERACTIONS_SENT_FAILED = 'ERR_4'
}

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    private matDialogRef!: MatDialogRef<ErrorDisplayComponent>;
    private listener: Subject<ErrorCodes> = new Subject();

    public constructor(
        private matDialog: MatDialog
    ) {
    }

    public display(code: ErrorCodes): void {

        this.listener.next(code);

        if (this.matDialogRef !== undefined) {
          this.matDialogRef.close();
        }
        this.matDialogRef = this.matDialog.open(ErrorDisplayComponent, {
            data: {
                code
            },
            panelClass: 'error-dialog',
            disableClose: true
        });
    }

    public onError(): Subject<ErrorCodes> {
      return this.listener;
    }
}
