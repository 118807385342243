<div class="basic-information-wrapper">
    <div>
        <div class="text-size-actions">
            <button mat-icon-button aria-label="text-down" [disabled]="zoom<=0" (click)="decreaseZoom()">
                <mat-icon>text_decrease</mat-icon>
            </button>
            <button mat-icon-button aria-label="text-up" [disabled]="zoom===maxZoomValue" (click)="increaseZoom()">
                <mat-icon>text_increase</mat-icon>
            </button>
        </div>
    </div>
    <div [ngClass]="{'text-size-xl': zoom === 3,'text-size-lg': zoom === 2,'text-size-md': zoom === 1, 'text-size-xs': zoom === 0}">
        <div class="title" *ngIf="title">
            {{title.props.content}}
        </div>
        <div *ngIf="paragraph" [ngSwitch]="paragraph.props.mimetype">
            <div *ngSwitchCase="'text/plain'" class="text">
                {{paragraph.props.content}}
            </div>
            <div *ngSwitchCase="'text/html'" class="text" [innerHTML]="paragraph.props.content | sanitizeHtml"></div>
        </div>
    </div>
</div>
