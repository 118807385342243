import {Component, Input, OnInit} from '@angular/core';
import {
  ImageMediaResource,
  RichTextMediaResource,
  TemplateContentCarouselImages
} from "@models/ventour";
import {from, Observable} from "rxjs";
import {LanguageService} from "@services/language.service";
import {TrackingService} from "@services/tracking.service";
import {
  FullscreenCarouselImagesComponent
} from "@components/fullscreen-carousel-images/fullscreen-carousel-images.component";
import {ImageSlider} from "@components/images-slider/images-slider.component";
import {VentourService} from "@services/ventour.service";


@Component({
  selector: 'app-template-content-carousel-images',
  templateUrl: './template-content-carousel-images.component.html',
  styleUrls: ['./template-content-carousel-images.component.scss']
})
export class TemplateContentCarouselImagesComponent implements OnInit {

  @Input() public templateContent!: TemplateContentCarouselImages;
  @Input() public enableFullscreen: boolean = true;

  public lazyFullscreenCarouselImagesComponent: Observable<typeof FullscreenCarouselImagesComponent> = from(
      import('@components/fullscreen-carousel-images/fullscreen-carousel-images.component').then(
          (component) => component.FullscreenCarouselImagesComponent
      )
  );
  public slides: ImageSlider[] = [];
  private languageCodeSelected!: string;

  public constructor(
      private languageService: LanguageService,
      private trackingService: TrackingService,
      private ventourService: VentourService
  ) {
    this.languageCodeSelected = this.languageService.getLanguage();
  }

  public ngOnInit(): void {
    this.preset();
    this.languageService.languageChanged.subscribe((languageIsoCode: string) => {
      this.languageCodeSelected = languageIsoCode;
      this.preset();
    });
  }

  private preset(): void {

    this.slides = [];
    for (let carouselImageProperties of this.templateContent.carousel_images) {
      let carouselImage = this.ventourService.findProperty<ImageMediaResource>(
        carouselImageProperties.carousel_image,
        this.languageCodeSelected
      );
      if (carouselImage === null) {
        continue;
      }
      let imageCaption = this.ventourService.findProperty<RichTextMediaResource>(
        carouselImageProperties.image_caption,
        this.languageCodeSelected
      );
      this.slides.push({
        caption: imageCaption,
        image: carouselImage,
        position: carouselImageProperties.position as number
      });
    }
  }

  public onCarouselImageSlide(event: [number, number]): void {
    this.trackingService.slide(this.templateContent.carousel_images[0].uuid, event[0], event[1]);
  }

  public onExitModeFullScreen(timeElapsed: number): void {
    if (!this.templateContent.carousel_images.length) {
      return;
    }
    this.trackingService.fullscreenMode(this.templateContent.carousel_images[0].uuid, timeElapsed);
  }
}
