import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {VisitComponent} from "@visit/visit.component";
import {HomeComponent} from "@home/home.component";
import {LanguagesSelectionComponent} from "@languagesSelection/languages-selection.component";


const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'visit',
    component: VisitComponent,
    loadChildren: () => import('@visit/visit.module').then(m => m.VisitModule)
  },
  {
    path: 'languages-selection',
    component: LanguagesSelectionComponent
  },
  {
    path: 'preview/:sessionId',
    loadChildren: () => import('@preview/preview.module').then(m => m.PreviewModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
