import {Inject, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'appCdnResolver'
})
export class AppCdnResolverPipe implements PipeTransform {

  public constructor(
    @Inject('APP_CDN_HOST') private host: string,
    @Inject('APP_CDN_DEFAULT_NAMESPACE') private namespace: string
  ) { }

  public transform(uri: string, namespace: string = ''): string {
    if (namespace === '') {
      namespace = this.namespace;
    }
    return `${this.host}/${namespace}/${uri}`;
  }
}
