import {Component, Inject, OnInit} from '@angular/core';
import {ImageMediaResource} from "@models/ventour";
import SwiperCore, {Zoom} from "swiper";
import {TrackingService} from "@services/tracking.service";

@Component({
  selector: 'app-fullscreen-image',
  templateUrl: './fullscreen-image.component.html',
  styleUrls: ['./fullscreen-image.component.scss']
})
export class FullscreenImageComponent implements OnInit {

  public constructor(
      @Inject('InjectProvideData') public image: ImageMediaResource,
      private trackingService: TrackingService
  ) {
    SwiperCore.use([Zoom]);
  }

  public ngOnInit(): void {
  }

  public onZoomChange(event: [swiper: SwiperCore, scale: number, image: HTMLElement, slideEl: HTMLElement]): void {
      if (event[1] !== 1) {
          this.trackingService.zoomImage(this.image.uuid);
      }
  }
}
