import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "@env/environment";
import {VentourService} from "@services/ventour.service";
import {ActivatedRoute, Params} from "@angular/router";
import {IconLoaderService} from "@services/icon-loader.service";
import FingerprintJS, {Agent, GetResult} from "@fingerprintjs/fingerprintjs";
import {TrackingService} from "@services/tracking.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public constructor(
    private translateService: TranslateService,
    private ventourService: VentourService,
    private route: ActivatedRoute,
    private iconLoaderService: IconLoaderService,
    private trackingService: TrackingService
  ) {
  }

  public ngOnInit(): void {

    FingerprintJS.load()
      .then((fp: Agent) => fp.get())
      .then((finger: GetResult) => {
        this.trackingService.setFingerprint(finger.visitorId);
      });

    this.translateService.setDefaultLang(environment.i18n.defaultLanguage);
    this.iconLoaderService.load();
    /**
     * Only for WEB support
     */
    this.route.queryParams.subscribe((params: Params) => {
      if (params['environment'] && ['prod', 'qa'].includes(params['environment'])) {
        this.ventourService.setEnvironment(params['environment']);
      }
      if (params['publication_released_uuid']) {
        this.ventourService.setPublicationReleasedUuid(params['publication_released_uuid']);
      }
    });
  }
}
