import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TreeNode, TreeNodeLanguageExclusion} from "@models/ventour";
import {LanguageService} from "@services/language.service";

@Component({
  selector: 'app-navigation-tree-cards',
  templateUrl: './navigation-tree-cards.component.html',
  styleUrls: ['./navigation-tree-cards.component.scss']
})
export class NavigationTreeCardsComponent implements OnInit, OnChanges {

  @Output() public navigateTo: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  @Input() public treeNodes!: TreeNode[];

  public treeNodesFilteredByLanguage!: TreeNode[];

  public constructor(
      public languageService: LanguageService
  ) { }

  public ngOnChanges(changes: SimpleChanges) {
    this.preset();
  }

  public ngOnInit(): void {
    this.languageService.languageChanged.subscribe(() => {
      this.preset();
    });
    this.preset();
  }

  private preset(): void {
    this.treeNodesFilteredByLanguage = this.treeNodes.filter((treeNode: TreeNode) => {
      const isoCodes: string[] = treeNode.language_exclusion.map((treeNodeLanguageExclusion: TreeNodeLanguageExclusion) => {
        return treeNodeLanguageExclusion.language_iso_code;
      });
      return !isoCodes.includes(this.languageService.getLanguage());
    });
  }
}
