<div>
  <ng-template ngFor let-treeNode [ngForOf]="treeNodesFilteredByLanguage">
    <div [attr.id]="treeNode.uuid" class="content-card-wrapper" (click)="navigateTo.emit(treeNode)"
         *ngIf="treeNode.content_uuid">
      <app-content-card [uuid]="treeNode.content_uuid"
                        *ngIf="treeNode.interactive; else nonInteractive"></app-content-card>
    </div>
    <ng-template #nonInteractive>
      <div>
        <ng-template ngFor let-child [ngForOf]="treeNode.children">
          <div [attr.id]="child.uuid" (click)="navigateTo.emit(child)" *ngIf="child.content_uuid">
            <app-content-card [uuid]="child.content_uuid"></app-content-card>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </ng-template>
</div>
