<div class="quiz-wrapper">
    <div class="question-text">
        <div *ngIf="questionText" [ngSwitch]="questionText.props.mimetype">
            <div *ngSwitchCase="'text/plain'">
                {{questionText.props.content}}
            </div>
            <div *ngSwitchCase="'text/html'" [innerHTML]="questionText.props.content | sanitizeHtml">
                {{questionText.props.content}}
            </div>
        </div>
    </div>
    <div class="answers">
        <ng-template ngFor let-answer [ngForOf]="answers">
        <div class="answer">
            <div class="answer-index">
                <button mat-mini-fab (click)="setAnswer(answer)"></button>
            </div>
            <div class="answer-text">
                {{answer.text.props.content}}
            </div>
        </div>
        </ng-template>
    </div>
</div>
