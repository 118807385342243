import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {
  AudioMediaResource,
  TemplateContentVideo,
  VideoMediaResource
} from "@models/ventour";
import {AudioMulticastService} from "@services/audio-multicast.service";
import {LanguageService} from "@services/language.service";
import {TrackingService} from "@services/tracking.service";
import {VentourService} from "@services/ventour.service";

@Component({
  selector: 'app-template-content-video',
  templateUrl: './template-content-video.component.html',
  styleUrls: ['./template-content-video.component.scss']
})
export class TemplateContentVideoComponent implements OnInit, AfterViewInit {

  @Input() public templateContent!: TemplateContentVideo;
  @ViewChild('videoPlayer') public videoPlayer!: ElementRef<HTMLVideoElement>;
  @ViewChild('trackPlayer') public trackPlayer!: ElementRef<HTMLAudioElement>;

  public video: VideoMediaResource|null = null;
  public audioTrack: AudioMediaResource|null = null;
  private languageCodeSelected!: string;

  public constructor(
      private audioMulticastService: AudioMulticastService,
      private languageService: LanguageService,
      private trackingService: TrackingService,
      private ventourService: VentourService
  ) {
    this.languageCodeSelected = this.languageService.getLanguage();
  }

  public ngOnInit(): void {
    this.preset();
    this.languageService.languageChanged.subscribe((languageIsoCode: string) => {
      this.languageCodeSelected = languageIsoCode;
      this.preset();
      this.dispatch();
    });
  }

  public ngAfterViewInit(): void {
    this.dispatch();
  }

  public preset(): void {

    this.video = this.ventourService.findProperty<VideoMediaResource>(
      this.templateContent.single_video.default_video,
      this.languageCodeSelected
    );
    if (this.video !== null && !this.video.props.default.processed) {
        console.warn('Default video is not processed', this.video.uuid);
    }

    this.audioTrack = this.ventourService.findProperty<AudioMediaResource>(
      this.templateContent.single_video.audio_track,
      this.languageCodeSelected
    );
    if (this.audioTrack !== null && !this.audioTrack.props.file.processed) {
        console.warn('Sign language video is not processed', this.audioTrack.uuid);
    }

    // TODO: Implement interactive subtitles
    // TODO: Fullscreen mode and help message
  }

  private dispatch(): void {
    const list: ElementRef[] = [];
    if (this.audioTrack !== null) {
      this.trackPlayer.nativeElement.load();
      list.push(this.trackPlayer);
    }
    if (this.video !== null) {
      this.videoPlayer.nativeElement.load();
      list.push(this.videoPlayer);
    }
    this.audioMulticastService.audioMediaSourceListener.next(list);
  }
}
