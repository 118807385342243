import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {ImageSlider} from "@components/images-slider/images-slider.component";


@Component({
  selector: 'app-fullscreen-carousel-images',
  templateUrl: './fullscreen-carousel-images.component.html',
  styleUrls: ['./fullscreen-carousel-images.component.scss']
})
export class FullscreenCarouselImagesComponent implements OnInit {

  public constructor(
      @Inject('InjectProvideData') public slides: ImageSlider[]
  ) { }

  public ngOnInit(): void {
  }
}
