import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AccessibleInformationProperties, AudioMediaResource, VideoMediaResource} from "@models/ventour";
import {AudioMulticastService} from "@services/audio-multicast.service";
import {LanguageService} from "@services/language.service";
import {VentourService} from "@services/ventour.service";
import {CdnFileUploadedResolverPipe} from "@pipes/cdn-file-uploaded-resolver.pipe";
import {TrackingService} from "@services/tracking.service";
import {CdkDragEnd} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-accessibility-wrapper',
  templateUrl: './accessibility-wrapper.component.html',
  styleUrls: ['./accessibility-wrapper.component.scss']
})
export class AccessibilityWrapperComponent implements OnInit, AfterViewInit {

  @ViewChild('signLanguagePlayer') public signLanguagePlayer!: ElementRef<HTMLVideoElement>;
  @ViewChild('locutionAudioPlayerElement') public locutionAudioPlayerElement!: ElementRef<HTMLAudioElement>;
  @Input() public accessibility!: AccessibleInformationProperties;

  public locution: AudioMediaResource|null = null;
  public signLanguage: VideoMediaResource|null = null;
  private languageCodeSelected!: string;
  public hideSignLanguageFloating: boolean = false;
  public resizedSignLanguageFloating: boolean = false;
  public signLanguageFloatingCurrentPosition: {x: number; y: number;} = {x: 0, y: 0};

  public constructor(
    private audioMulticastService: AudioMulticastService,
    private languageService: LanguageService,
    private ventourService: VentourService,
    private cdnFileUploadedResolver: CdnFileUploadedResolverPipe,
    private trackingService: TrackingService
  ) {
    this.languageCodeSelected = this.languageService.getLanguage();
  }

  public ngOnInit(): void {
    this.preset();
    this.languageService.languageChanged.subscribe((languageIsoCode: string) => {
      this.languageCodeSelected = languageIsoCode;
      this.preset();
      this.clearLocutionSource();
      this.dispatch();
    });
  }

  public ngAfterViewInit(): void {
    this.dispatch();
  }

  private dispatch(): void {
    const elements: ElementRef[] = [];
    if (this.locution && this.locution.props.file.processed) {
      this.setLocutionSource();
      elements.push(this.locutionAudioPlayerElement);
    }
    if (this.signLanguage !== null) {
      this.signLanguagePlayer.nativeElement.load();
      elements.push(this.signLanguagePlayer);
    }
    this.audioMulticastService.audioMediaSourceListener.next(elements);
  }

  private preset(): void {
    this.locution = this.ventourService.findProperty<AudioMediaResource>(
      this.accessibility.locution,
      this.languageCodeSelected
    );
    this.signLanguage = this.ventourService.findProperty<VideoMediaResource>(
      this.accessibility.sign_language,
      this.languageCodeSelected
    );
    if (this.signLanguage !== null && !this.signLanguage.props.small.processed) {
      console.warn('Sign language video is not processed', this.signLanguage.uuid);
    }
  }

  private setLocutionSource(): void {
    if (this.locution === null) {
      return;
    }
    const source: HTMLElement = document.createElement('source');
    source.setAttribute('src', this.cdnFileUploadedResolver.transform(this.locution.props.file));
    source.setAttribute('type', 'audio/mpeg');

    this.locutionAudioPlayerElement.nativeElement.appendChild(source);
    this.locutionAudioPlayerElement.nativeElement.load();
  }

  private clearLocutionSource(): void {
    if (!this.locutionAudioPlayerElement.nativeElement.paused) {
      this.locutionAudioPlayerElement.nativeElement.pause();
    }
    this.locutionAudioPlayerElement.nativeElement.innerHTML = '';
  }

  public floatingSignLanguageEvent(type: 'close' | 'resize' | 'move'): void {
    if (!this.signLanguage) {
      return;
    }
    this.trackingService.floatingSignLanguage(
      this.signLanguage?.uuid,
      this.signLanguageFloatingCurrentPosition.x,
      this.signLanguageFloatingCurrentPosition.y,
      type,
      this.resizedSignLanguageFloating
    );
  }

  public onDragEnded(event: CdkDragEnd): void {
    const x: number = parseFloat(((event.source.getFreeDragPosition().x * 100) / window.innerWidth).toFixed(2));
    const y: number = parseFloat(((event.source.getFreeDragPosition().y * 100) / window.innerHeight).toFixed(2));

    if (!isNaN(x) && !isNaN(y)) {
      this.signLanguageFloatingCurrentPosition.x = x;
      this.signLanguageFloatingCurrentPosition.y = y;
      this.floatingSignLanguageEvent('move');
    }
  }

  public resizeSignLanguageFloating(): void {
    this.resizedSignLanguageFloating = !this.resizedSignLanguageFloating;
    this.floatingSignLanguageEvent('resize');
  }

  public closeSignLanguageFloating(): void {
    this.hideSignLanguageFloating = true;
    this.floatingSignLanguageEvent('close');
  }
}
