import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

  public constructor(
      private domSanitizer: DomSanitizer
  ) { }

  public transform(input: string|null|undefined): SafeHtml {
    if (input) {
      return this.domSanitizer.bypassSecurityTrustHtml(input);
    }
    return '';
  }
}
