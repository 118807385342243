<mat-card *ngIf="title" role="button" class="is-focusable">
    <mat-card-content>
        <div class="thumbnail">
            <img *ngIf="coverImage; else defaultImage" [src]="coverImage.props.medium | cdnFileUploadedResolver" alt="Content image"/>
            <ng-template #defaultImage>
                <img src="./assets/images/default-image.jpg" alt="Content image"/>
            </ng-template>
        </div>
        <div class="information">
            <div class="title" *ngIf="title">
                {{title.props.content}}
            </div>
            <div class="keyboard-code" *ngIf="keyboardCode">
                <mat-icon>headphones</mat-icon>
                {{keyboardCode}}
            </div>
        </div>
        <div class="arrow">
            <mat-icon>chevron_right</mat-icon>
        </div>
    </mat-card-content>
</mat-card>

