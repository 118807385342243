import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public languageChanged: Subject<string> = new Subject<string>();

  public constructor(
    private translateService: TranslateService
  ) {
  }

  public setLanguage(isoCode: string): void {
    this.translateService.use(isoCode);
    this.languageChanged.next(isoCode);
  }

  public getLanguage(): string {
    return this.translateService.currentLang || this.translateService.defaultLang;
  }
}
