<app-accessibility-wrapper [accessibility]="templateContent.accessible_information">
  <div block1 *ngIf="image!==null">
    <div *ngIf="enableFullscreen; else imageTemplate">
      <app-fullscreen-action [dynamicComponent]="lazyFullscreenImageComponent"
                             [dynamicComponentInjectProvideData]="image"
                             (onExitMode)="onExitModeFullScreen($event)">
        <div class="image-wrapper" content>
          <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
        </div>
      </app-fullscreen-action>
    </div>
    <ng-template #imageTemplate>
      <img [src]="image.props.medium | cdnFileUploadedResolver" alt="Default image" />
    </ng-template>
  </div>
  <div block2>
    <app-basic-information [properties]="templateContent.basic_information"></app-basic-information>
  </div>
</app-accessibility-wrapper>

