<mat-toolbar class="toolbar">
    <!-- TODO: Error in some devices, title is not visible for camera -->
    <button (click)="back()" class="before" mat-icon-button aria-label="navigate_before">
        <mat-icon>navigate_before</mat-icon>
    </button>
    <h1>{{ title }}</h1>
    <button mat-icon-button class="open-menu-action" (click)="openSidenav()">
        <mat-icon>menu</mat-icon>
    </button>
</mat-toolbar>

