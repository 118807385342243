<mat-dialog-content>
    <div class="dialog-header">
        <div *ngIf="data.title">
            {{data.title.props.content}}
        </div>
        <div>
            <button mat-icon-button (click)="dialogRef.close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <img *ngIf="data.image; else defaultImage" [src]="data.image.props.medium | cdnFileUploadedResolver" alt="Tour image"/>
    <ng-template #defaultImage>
       <img src="./assets/images/default-image.jpg" alt="Tour image"/>
    </ng-template>
    <div class="confirmation-message">
        <div *ngIf="data.confirmationMessage!==null">
            <div *ngIf="data.confirmationMessage.props.mimetype==='text/html'" [innerHTML]="data.confirmationMessage.props.content | sanitizeHtml"></div>
            <div *ngIf="data.confirmationMessage.props.mimetype==='text/plain'">
                {{data.confirmationMessage.props.content}}
            </div>
        </div>
    </div>
    <div class="accessibility-wrapper" *ngIf="data.enableSubtitles||data.enableSignLanguage">
        <div class="header">
            {{'i18n.accessibilityOptions' | translate}}
        </div>
        <!-- Enable sign language action -->
        <div class="action" *ngIf="data.enableSignLanguage">
            <div class="label">
                <mat-icon svgIcon="sign_language" aria-hidden="false" aria-label="sign-language"></mat-icon>
                {{'i18n.enableSignLanguage' | translate }}
            </div>
            <div class="switch">
                <mat-slide-toggle labelPosition="before" [(ngModel)]="answeredAccessibilitySignsLanguage"></mat-slide-toggle>
            </div>
        </div>
        <!-- Enable subtitles action -->
        <div class="action" *ngIf="data.enableSubtitles">
            <div class="label">
                <mat-icon svgIcon="subtitle" aria-hidden="false" aria-label="sign-language"></mat-icon>
                {{'i18n.enableSubtitles' | translate }}
            </div>
            <div class="switch">
                <mat-slide-toggle labelPosition="before" [(ngModel)]="answeredAccessibilitySubtitles"></mat-slide-toggle>
            </div>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="confirm()">
        {{'i18n.startTour' | translate}}
    </button>
</mat-dialog-actions>

