import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ErrorCodes} from "../../services/error.service";

@Component({
  selector: 'app-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss']
})
export class ErrorDisplayComponent {

  public constructor(
      @Inject(MAT_DIALOG_DATA) public data: {code: ErrorCodes},
      public dialogRef: MatDialogRef<ErrorDisplayComponent>,
      private router: Router,
      private matDialog: MatDialog
  ) { }

  public redirectToHome(): void {
    this.router.navigate(['/home']).then(null);
    this.matDialog.closeAll();
  }
}
